import { AgGrid, ETFCard, Layout } from '@cfra-nextgen-frontend/shared';
import { getDownloadAction } from '@cfra-nextgen-frontend/shared/src/analytics/utils';
import { AgGridProps } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import {
    BreadcrumbConfig,
    ColumnDef,
    IServerSideDatasource,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { Link } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { ChartDescription } from '@cfra-nextgen-frontend/shared/src/components/Chart';
import { ChartTabs, ChartTabsProps } from '@cfra-nextgen-frontend/shared/src/components/Chart/ChartTabs';
import { TableExportMenus } from '@cfra-nextgen-frontend/shared/src/components/Chart/ExportMenus';
import {
    IMetadataFields
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { AgGridExportProps } from '@cfra-nextgen-frontend/shared/src/components/excelExport/export';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Categories, fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Grid, SxProps, Typography, styled, useMediaQuery } from '@mui/material';
import { CsvExportParams, RowHeightParams, SortModelItem } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { CategoriesInfo } from './CategoriesInfo';
import { setCustomFlexWidths } from './utils';

export type ExportAgGrid = (
    exportRef: React.RefObject<AgGridReact<any>> | undefined,
    agGridExportProps: AgGridExportProps,
    fieldMetadata?: IMetadataFields[] | undefined,
) => void;

export type ExportSSRAgGrid = (
    exportRef: React.RefObject<AgGridReact<any>> | undefined,
    SSRDataExportFn: (sortColumns: SortModelItem[]) => Promise<any[]>,
    agGridExportProps: AgGridExportProps,
    fieldMetadata?: IMetadataFields[] | undefined,
) => void;

export type ExportProps = {
    // ExportProps which also contains in TopPanelProps
    dateRangesPanelConfig?: Array<Layout.ETFButtonsPannelButtonProps>;
    label?: string;

    // Props unique for ExportProps
    disableMenu?: boolean;
    excelExportAsOfDateField?: string;
    excelExportUseMaxAsOfDate?: boolean;
    exportRef?: React.RefObject<AgGridReact>;
    selectedCategory?: string;
    getExportButton?: (onClick?: () => void) => JSX.Element;
    SSRDataExportFn?: (sortColumns: SortModelItem[]) => Promise<Array<any>>;
    getAnalyticsCallback?: (agGridExportProps: AgGridExportProps) => (type: string) => void;
    getExportCallback?: (agGridExportProps: AgGridExportProps) => () => void;
    exportAgGrid?: ExportAgGrid;
    exportSSRAgGrid?: ExportSSRAgGrid;
    getFormattedValuesFirst?: boolean;
    fieldMetadata?: IMetadataFields[];
};

export type TopPanelProps = {
    breadcrumbsConfig?: Array<BreadcrumbConfig>;
    categoriesPanelConfig?: ChartTabsProps;
    dateRangesPanelConfig?: Array<Layout.ETFButtonsPannelButtonProps>;
    description?: string;
    label?: string;
    labelPanelContainerStyles?: SxProps;
    labelProps?: any;
    topLeftActionPanelItems?: React.ReactNode[];
    topLeftActionPanelStyles?: SxProps;
    topPanelSlot1Content?: React.ReactNode;
    topPanelSlot2Content?: React.ReactNode;
    topPanelSlot3Content?: React.ReactNode;
    topRightActionPanelItems?: React.ReactNode[];
    topRightActionPanelStyles?: SxProps;
    showDefaultExportButton?: boolean;
} & ExportProps;

type AgGirdCardProps = {
    columnDefs: Array<ColumnDef>;
    containerStyles?: SxProps;
    customFlexibleColumns?: Array<string>;
    getResizableMinWidthForColumn: (key: string) => number;
    getRowID?: (params: any) => string;
    maxNumberOfRowsToDisplay?: number;
    rowMultiSelectWithClick?: boolean;
    rowsData?: Array<any>;
    rowSelection?: 'single' | 'multiple';
    SSRDataSource?: IServerSideDatasource;
    SSRrowsToFetch?: number;
    suppressRowClickSelection?: boolean;
    useSSRMode?: boolean;
    defaultMaxWidth?: number | null;
    getRowHeight?: (params: RowHeightParams) => number | undefined | null;
    maxGridContainerHeightPercentage?: AgGridProps['maxGridContainerHeightPercentage'];
    unlimitedCalculatedHeight?: AgGridProps['unlimitedCalculatedHeight'];
    horizontalScrollbarAreaHeight?: AgGridProps['horizontalScrollbarAreaHeight'];
    enableAutoSizeAllColumns?: AgGridProps['enableAutoSizeAllColumns'];
    onModelUpdatedRef?: AgGridProps['onModelUpdatedRef'];
    onFirstDataRenderedRef?: AgGridProps['onFirstDataRenderedRef'];
    onSortChangedRef?: AgGridProps['onSortChangedRef'];
    fullHeightGrid?: AgGridProps['fullHeightGrid'];
    gridTheme?: AgGridProps['gridTheme'];
    useAutoHeight?: boolean;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    embedFullWidthRows?: boolean;
    defaultRowHeight?: AgGridProps['defaultRowHeight'];
    defaultCsvExportParams?: CsvExportParams;
} & TopPanelProps;

export function TopPanelRow(props: { children: React.ReactNode }) {
    return (
        <Grid
            item
            container
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px 28px', paddingTop: '0px' }}>
            {props.children}
        </Grid>
    );
}

export function AgGirdExportButton({
    dateRangesPanelConfig,
    disableMenu,
    excelExportAsOfDateField,
    excelExportUseMaxAsOfDate,
    exportRef,
    label,
    selectedCategory,
    SSRDataExportFn,
    getAnalyticsCallback,
    getExportButton,
    getExportCallback,
    exportAgGrid,
    exportSSRAgGrid,
    getFormattedValuesFirst,
    fieldMetadata,
}: ExportProps): JSX.Element {
    const agGridExportProps: AgGridExportProps = useMemo(
        () => ({
            asOfDateField: excelExportAsOfDateField,
            autoSizeColumns: true,
            fileName: label,
            metadata: {
                cardName: label || '',
            },
            sheetName: 'Data Details',
            useMaxAsOfDate: excelExportUseMaxAsOfDate,
            getFormattedValuesFirst: getFormattedValuesFirst,
        }),
        [excelExportAsOfDateField, excelExportUseMaxAsOfDate, label, getFormattedValuesFirst],
    );

    const exportCallback = useMemo(() => {
        let _exportCallback = getExportCallback?.(agGridExportProps);
        if (!_exportCallback) {
            _exportCallback = () => {
                if (!SSRDataExportFn) {
                    exportAgGrid?.(exportRef, agGridExportProps, fieldMetadata);
                } else {
                    exportSSRAgGrid?.(exportRef, SSRDataExportFn, agGridExportProps, fieldMetadata);
                }
            };
        }
        return _exportCallback;
    }, [getExportCallback, agGridExportProps, SSRDataExportFn, exportAgGrid, exportRef, fieldMetadata, exportSSRAgGrid]);

    const analyticsCallback = useMemo(() => {
        let _analyticsCallback = getAnalyticsCallback?.(agGridExportProps);
        if (!_analyticsCallback) {
            _analyticsCallback = (type: string) =>
                globalThis.analytics?.registerAction?.({
                    action: getDownloadAction(type),
                    cardName: label,
                    dateRange: dateRangesPanelConfig?.find((element) => Boolean(element.isDefault))?.name,
                    reportName: agGridExportProps.fileName,
                    reportType: type,
                    selectedCategory: selectedCategory,
                });
        }
        return _analyticsCallback;
    }, [agGridExportProps, getAnalyticsCallback, dateRangesPanelConfig, label, selectedCategory]);

    if (disableMenu && getExportButton) {
        return getExportButton(() => {
            exportCallback();
            analyticsCallback('');
        });
    }

    return (
        <TableExportMenus
            analyticsCallback={analyticsCallback}
            exportCallback={exportCallback}
            menuActionButton={getExportButton?.()}
        />
    );
}

export function getSelectedCategory(categoriesPanelConfig?: ChartTabsProps) {
    if (categoriesPanelConfig?.currentActiveTab === false) {
        return 'no categories selected';
    }

    if (typeof categoriesPanelConfig?.currentActiveTab === 'number') {
        return Object.values(Categories)[categoriesPanelConfig.currentActiveTab];
    }
}

const TopRightActionBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap-reverse',
    justifyContent: 'right',
    rowGap: '8px',
}));

const TopLeftActionBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '21px',
    flexWrap: 'wrap',
    rowGap: '8px',
}));

export function AgGirdCardTopPanel(props: TopPanelProps) {
    const {
        breadcrumbsConfig,
        categoriesPanelConfig,
        dateRangesPanelConfig,
        description,
        excelExportAsOfDateField,
        excelExportUseMaxAsOfDate,
        getExportButton,
        exportRef,
        label,
        labelPanelContainerStyles,
        labelProps,
        SSRDataExportFn,
        showDefaultExportButton = true,
        exportAgGrid,
        exportSSRAgGrid,
    } = props;
    const [showExportButton, setShowExportButton] = useState(false);
    const isBelowSm = useMediaQuery(breakpointsTheme.breakpoints.down('sm'));

    useEffect(() => {
        if (exportRef && exportRef.current) {
            setShowExportButton(true);
        }
    }, [exportRef]);

    let selectedCategory = useMemo(() => getSelectedCategory(categoriesPanelConfig), [categoriesPanelConfig]);

    return (
        <>
            {label || description || props.topLeftActionPanelItems || props.topRightActionPanelItems ? (
                <Grid container sx={{ padding: '28px 28px 0px 28px', ...labelPanelContainerStyles }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TopLeftActionBox sx={props.topLeftActionPanelStyles}>
                            {label && <ETFCard.ItemHeader sx={{ ...(labelProps || {}) }}>{label}</ETFCard.ItemHeader>}
                            {props.topLeftActionPanelItems}
                        </TopLeftActionBox>
                        <TopRightActionBox sx={props.topRightActionPanelStyles}>
                            {props.topRightActionPanelItems}
                            {label && showDefaultExportButton && showExportButton ? (
                                <AgGirdExportButton
                                    label={label}
                                    SSRDataExportFn={SSRDataExportFn}
                                    exportRef={exportRef}
                                    excelExportAsOfDateField={excelExportAsOfDateField}
                                    excelExportUseMaxAsOfDate={excelExportUseMaxAsOfDate}
                                    dateRangesPanelConfig={dateRangesPanelConfig}
                                    selectedCategory={selectedCategory}
                                    getExportButton={getExportButton}
                                    exportAgGrid={exportAgGrid}
                                    exportSSRAgGrid={exportSSRAgGrid}
                                />
                            ) : null}
                        </TopRightActionBox>
                    </Grid>
                    <Grid item xs={12}>
                        {description ? <ChartDescription>{description}</ChartDescription> : null}
                    </Grid>
                </Grid>
            ) : null}
            {dateRangesPanelConfig || categoriesPanelConfig ? (
                <TopPanelRow>
                    {categoriesPanelConfig ? (
                        <Grid item sx={{ paddingBottom: '36px' }} xs={isBelowSm ? 12 : undefined}>
                            <Grid container>
                                <ChartTabs
                                    {...categoriesPanelConfig}
                                    tabsStyles={{
                                        fontFamily: fontFamilies.GraphikMedium,
                                        fontSize: '15px',
                                    }}
                                />
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CategoriesInfo
                                        buttonFontSize={14}
                                        analyticsOpenModalProps={{
                                            cardName: label,
                                            dateRange: dateRangesPanelConfig?.find((element) =>
                                                Boolean(element.isDefault),
                                            )?.name,
                                            selectedCategory: selectedCategory,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {dateRangesPanelConfig ? (
                        <Grid item sx={{ paddingBottom: '36px' }} xs={isBelowSm ? 12 : undefined}>
                            <Layout.ETFButtonsPannel
                                buttonsConfig={dateRangesPanelConfig}
                                buttonsStyles={{ fontSize: '15px' }}
                            />
                        </Grid>
                    ) : null}
                </TopPanelRow>
            ) : null}
            {breadcrumbsConfig ? (
                <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                    sx={{ paddingLeft: '28px', paddingBottom: '12px' }}>
                    {breadcrumbsConfig.map((config, index) => {
                        if (index + 1 === breadcrumbsConfig.length) {
                            return <Typography key={index}>{config.text}</Typography>;
                        }
                        return <Link key={index} value={config.text} handleOpen={config.callback} />;
                    })}
                </Breadcrumbs>
            ) : null}
            {props.topPanelSlot1Content}
            {props.topPanelSlot2Content}
            {props.topPanelSlot3Content}
        </>
    );
}

export const defaultAgGirdCardRowHeight = 46;

export const AgGirdCard = forwardRef<AgGridReact, AgGirdCardProps>((props, ref) => {
    const isBelowLg = useMediaQuery(breakpointsTheme.breakpoints.down('lg'));

    const gridRef = useRef<AgGridReact>(null);
    useImperativeHandle(ref, () => gridRef.current!);

    return (
        <ETFCard.ETFCard containerStyles={{ padding: '0px', ...props.containerStyles }}>
            <AgGirdCardTopPanel {...props} exportRef={gridRef} />
            <AgGrid.AgGrid
                {...props}
                ref={gridRef}
                updateCustomColumnsWidths={
                    props.customFlexibleColumns
                        ? setCustomFlexWidths(isBelowLg, props.customFlexibleColumns)
                        : undefined
                }
                defaultRowHeight={props.defaultRowHeight || defaultAgGirdCardRowHeight}
                defaultMaxWidth={props.defaultMaxWidth || null}
                customFlexibleColumns={props.customFlexibleColumns}
                maxGridContainerHeightPercentage={props.maxGridContainerHeightPercentage}
                unlimitedCalculatedHeight={props.unlimitedCalculatedHeight}
                defaultCsvExportParams={props.defaultCsvExportParams}
            />
        </ETFCard.ETFCard>
    );
});
