import {
    getExportAgGrid,
    getExportExcel,
    getExportSSRAgGrid,
} from '@cfra-nextgen-frontend/shared/src/components/excelExport/export';
import { ApplicationType } from '@cfra-nextgen-frontend/shared/src/utils';
import logoFile from 'assets/images/FUNDynamixLogo.png';

export const exportExcel = getExportExcel(logoFile, ApplicationType.FUNDynamix);

export const exportAgGrid = getExportAgGrid(exportExcel);

export const exportSSRAgGrid = getExportSSRAgGrid(exportExcel);
